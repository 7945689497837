<template>
<div>
    <b-row>
        <b-col cols="12">
            <b-card class="my-card m-0">
                <b-table 
                striped
                small
                id="myTables"
                bordered
                selectable
                selected-variant="primary"
                select-mode="single"
                responsive="sm"
                @row-clicked="clicked"
                :items="unfoundAddresses"
                :fields="fields"
                head-row-variant="secondary"
                >
                <template #cell(checkbox)="row">
                    <b-form-checkbox
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="row.toggleDetails"
                    >
                    </b-form-checkbox>
                </template>    
            </b-table>
        </b-card>
    </b-col>
    </b-row>
</div>
    
</template>
<script>
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
    props: ['unfoundAddresses','id'],
    data(){
        return {
            fields: [ 
                { key: 'checkbox', label: '', thStyle: {width: '30px'}},
                { key: 'id', label: 'ID', sortable: true, thStyle: {width: '40px'}},
                { key: 'name', label: 'Название', sortable: true, thStyle: {width: '150px'}},
                { key: 'created_at_time', label: 'Дата поиска', sortable: true, thStyle: {width: '150px'}},
                { key: 'created_by_login', label: 'Создан с помощью логина', sortable: true, thStyle: {width: '150px'}},
            ],
        }
    },
    mounted(){
        resizeable()
    },
    methods:{
        clicked(item, index, event){
            this.$store.state.filterPlus.tableIndex = index

            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                return;
            }

            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;
            this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
            cellSelect()
        },
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/table.scss';
.my-card .card-body {
    padding: 0px;
}
.my-card {
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto; 
    height: 530px;
    }
}
</style>